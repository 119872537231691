






import { Component, Vue } from 'vue-property-decorator';
// import * as Sentry from '@sentry/vue';

@Component
export default class FeedbackButton extends Vue {
  public submitUserFeedback() {
    const error = new Error('feedback');
    // Sentry.captureException(error);
  }
}
