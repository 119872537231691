import { ISessionDataState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { IState } from '../state';
import { QuestionTypes, IScenarioData } from '@/interfaces';

export const getters = {
  sessionData: (state: ISessionDataState) => {
    return state.data;
  },
  sessionDataOpenEnded: (state: ISessionDataState) => {
    return state.open_ended_question_topics;
  },
  sessionAIRecruitment: (state: ISessionDataState) => {
    return state.ai_recruitment;
  },
  sessionResponses: (state: ISessionDataState) => {
    return state.responses;
  },
  questionData: (state: ISessionDataState) => (questionId: string) => {
    return state.data.questions_with_data.find(q => q.id == questionId);
  },
  questionResponses: (state: ISessionDataState) => (questionId: string) => {
    return state.responses.questions.find(q => q.id == questionId);
  },
  questionResponseData: (state: ISessionDataState) => (questionId: string) => {
    return state.response_data.questions.find(q => q.id == questionId);
  },
  questionTopics: (state: ISessionDataState) => (questionId: string) => {
    return state.topics.questions.find(q => q.id == questionId);
  },
  latestQuestionEstimates: (state: ISessionDataState) => (questionId: string) => {
    const filteredQuestions = state.data.questions_with_data.filter((q) => q.id === questionId);
    if (filteredQuestions.length > 0) {
      const data = filteredQuestions[0].data;
      if (Array.isArray(data) && filteredQuestions[0].type == QuestionTypes.scenario) {
        return Object.assign({}, ...(data as IScenarioData[]).map((q) => ({ [q.contract.text]: q.estimates[q.estimates.length - 1].estimate })));
      }
      else if ('estimates' in data) {
        return data.estimates[data.estimates.length - 1].estimate;
      }
    }
  },
};

const { read } = getStoreAccessors<ISessionDataState, IState>('');

export const readSessionData = read(getters.sessionData);
export const readSessionResponses = read(getters.sessionResponses);
export const readQuestionData = read(getters.questionData);
export const readQuestionResponses = read(getters.questionResponses);
export const readQuestionResponseData = read(getters.questionResponseData);
export const readQuestionTopics = read(getters.questionTopics);
export const readLatestQuestionEstimates = read(getters.latestQuestionEstimates);
export const readSessionAIRecruitment = read(getters.sessionAIRecruitment);
export const readSessionDataOpenEnded = read(getters.sessionDataOpenEnded);