import { IUserProfile, ISession, IBetaSignee, ILucidSettings, IProlificSettings } from '@/interfaces';
import { IAdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { IState } from '../state';

export const mutations = {
  setUsers(state: IAdminState, payload: IUserProfile[]) {
    state.users = payload;
  },
  setUser(state: IAdminState, payload: IUserProfile) {
    const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
    users.push(payload);
    state.users = users;
  },
  deleteUser(state: IAdminState, payload: IUserProfile) {
    const filteredUsers = state.users.filter((user: IUserProfile) => user.id !== payload.id);
    state.users = filteredUsers;
  },
  deleteBetaSignee(state: IAdminState, payload: IBetaSignee) {
    const filteredBetaSignees = state.betaSignees.filter((betaSignee: IBetaSignee) => betaSignee.id !== payload.id);
    state.betaSignees = filteredBetaSignees;
  },
  setAdminSessions(state: IAdminState, payload: ISession[]) {
    state.sessions = payload;
  },
  setAdminSession(state: IAdminState, payload: ISession) {
    const sessions = state.sessions.filter((session: ISession) => session.id !== payload.id);
    sessions.push(payload);
    state.sessions = sessions;
  },
  setBetaSignees(state: IAdminState, payload: IBetaSignee[]) {
    state.betaSignees = payload;
  },
  setBetaSignee(state: IAdminState, payload: IBetaSignee) {
    const betaSignees = state.betaSignees.filter((betaSignee: IBetaSignee) => betaSignee.id !== payload.id);
    betaSignees.push(payload);
    state.betaSignees = betaSignees;
  },
  setLucidSettings(state: IAdminState, payload: ILucidSettings) {
    state.lucidSettings = payload;
  },
  setProlificSettings(state: IAdminState, payload: IProlificSettings) {
    state.prolificSettings = payload;
  },
};

const { commit } = getStoreAccessors<IAdminState, IState>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitDeleteUser = commit(mutations.deleteUser);
export const commitDeleteBetaSignee = commit(mutations.deleteBetaSignee);
export const commitSetAdminSessions = commit(mutations.setAdminSessions);
export const commitSetAdminSession = commit(mutations.setAdminSession);
export const commitSetBetaSignee = commit(mutations.setBetaSignee);
export const commitSetBetaSignees = commit(mutations.setBetaSignees);
export const commitSetLucidSettings = commit(mutations.setLucidSettings);
export const commitSetProlificSettings = commit(mutations.setProlificSettings);
