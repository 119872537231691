import { IExternalParticipants } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { IState } from '../state';

export const getters = {
  numberOfParticipants: (state: IExternalParticipants) => {
    return state.participants.male_participants;
  },
  lucidFeasibility: (state: IExternalParticipants) => {
    return Math.round(state.lucid_audience_estimate.feasibility);
  },
  lucidPrice: (state: IExternalParticipants) => {
    return state.lucid_audience_estimate.price;
  },
  lucidSampleProbability: (state: IExternalParticipants) => {
    return state.lucid_audience_estimate.sample_probability;
  },
};
const { read } = getStoreAccessors<IExternalParticipants, IState>('');
export const readNumberOfParticipants = read(getters.numberOfParticipants);
export const readLucidFeasibility = read(getters.lucidFeasibility);
export const readLucidPrice = read(getters.lucidPrice);
export const readLucidSampleProbability = read(getters.lucidSampleProbability);