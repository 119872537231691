import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { mainModule } from './main';
import { SessionModule } from './sessions';
import { SessionDataModule } from './sessionData';
import { RecruitDataModule } from './recruitData';
import { IState } from './state';
import { adminModule } from './admin';

Vue.use(Vuex);

const storeOptions: StoreOptions<IState> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    sessions: SessionModule,
    sessionData: SessionDataModule,
    recruitData: RecruitDataModule
  },
};

export const store = new Vuex.Store<IState>(storeOptions);

export default store;
