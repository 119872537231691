import { ISession } from '@/interfaces';
import { ISessionsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { IState } from '../state';

export const mutations = {
  setSessions(state: ISessionsState, payload: ISession[]) {
    state.sessions = payload;
  },
  setSession(state: ISessionsState, payload: ISession) {
    const sessions = state.sessions.filter((session: ISession) => session.id !== payload.id);
    sessions.push(payload);
    state.sessions = sessions;
  },
  deleteSession(state: ISessionsState, payload: ISession) {
    const sessions = state.sessions.filter((session: ISession) => session.id !== payload.id);
    state.sessions = sessions;
  },
};

const { commit } = getStoreAccessors<ISessionsState, IState>('');

export const commitSetSession = commit(mutations.setSession);
export const commitSetSessions = commit(mutations.setSessions);
export const commitDeleteSessions = commit(mutations.deleteSession);
