import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  'EN-GB': {
    ui: {
      please_stand_by: 'Please stand by',
    },
    chat: {
      bot_typing: 'Masspredict is typing',
      estimate_slider: 'I think it\'s %{value}% likely.',
      privacy_header: 'Privacy policy',
      please_read: 'Please read',
      privacy_first_line: 'This platform belongs to Masspredict, a Swedish company based in Stockholm.',
      privacy_second_line: 'By using our platform, you agree to having us store the responses you give during studies, like the one you will be taking part in shortly.',
      privacy_third_line: 'All of your responses are completely anonymous. We have no way of knowing who you are.',
      privacy_fourth_line: 'If you have any questions about or feedback on the platform, feel free to email us: info@masspredict.io.',
      privacy_collect_email: 'We will ask for your email at completion so we can get in touch about any study reward. Your email will be used for no other purpose, and won\'t be associated with your responses.',
      privacy_collect_gener: 'We will ask for your gender at the start of the study. This information is only used to analyse the results in the aggregate.',
      privacy_ok_button: 'OK',
      privacy_cancel_button: 'Cancel',
    },
    study: {
      why_do_you_think_that: 'Why do you think that?',
      scenario_how_likely_do_you_think: 'How likely do you think "%{contract}" is in light of what you just read?',
      binary_how_likely_do_you_think: 'Taking that into account, how likely do you now think this is?',
      probability: 'probability',
      scenario_with_a_probability_of: '"%{contract}" with a probability of %{estimate}',
    }
  },
  'SV': {
    ui: {
      please_stand_by: 'Vänligen stå till tjänst',
    },
    chat: {
      bot_typing: 'Masspredict skriver',
      estimate_slider: 'Jag tror det är %{value}% sannolikt.',
      privacy_header: 'Integritetspolicy',
      please_read: 'Vänligen läs',
      privacy_first_line: 'Denna plattform tillhör Masspredict, ett svenskt företag med säte i Stockholm.',
      privacy_second_line: 'Genom att använda vår plattform godkänner du att vi lagrar de svar du ger under studier, såsom den du snart kommer att delta i.',
      privacy_third_line: 'Alla dina svar är helt anonyma.',
      privacy_fourth_line: 'Om du har några frågor eller feedback om plattformen kan du skicka ett e-postmeddelande till oss: info@masspredict.io.',
      privacy_collect_email: 'Vi kommer att be om din e-postadress när du är klar så att vi kan kontakta dig om eventuella belöningar för studien. Din e-postadress kommer inte att användas i något annat syfte och kommer inte att förknippas med dina svar.',
      privacy_collect_gener: 'Vi kommer att fråga efter ditt kön i början av studien. Denna information används endast för att analysera resultaten i sin helhet.',
      privacy_ok_button: 'OK',
      privacy_cancel_button: 'Avbryt',
    },
    study: {
      why_do_you_think_that: 'Varför tror du det?',
      scenario_how_likely_do_you_think: 'Hur sannolikt tror du att "%{contract}" är mot bakgrund av det du just läste?',
      binary_how_likely_do_you_think: 'Med tanke på detta, hur troligt tror du nu att detta är?',
      probability: 'sannolikt',
      scenario_with_a_probability_of: '"%{contract}" med en sannolikhet på %{estimate}',
    }
  },
  'ZH' : {
    ui: {
      please_stand_by: '请待命',
    },
    chat: {
      bot_typing: 'Masspredict正在打字',
      estimate_slider: '我认为有 %{value}% 的可能性。',
      privacy_header: '隐私政策',
      please_read: '请阅读',
      privacy_first_line: '这个平台属于Masspredict，一家位于斯德哥尔摩的瑞典公司。',
      privacy_second_line: '通过使用我们的平台，你同意我们储存你在研究期间所做的反应，比如你即将参加的研究。',
      privacy_third_line: '你的所有答复都是完全匿名的。我们没有办法知道你是谁。',
      privacy_fourth_line: '如果您对该平台有任何疑问或反馈，请随时向我们发送电子邮件：info@masspredict.io。',
      privacy_collect_email: '我们会在结束时要求您提供电子邮件，以便我们能够就任何研究的回报进行联系。您的电子邮件不会被用于其他目的，也不会与您的答复相关联。',
      privacy_collect_gener: '我们会在研究开始时询问你的性别。这些信息只用于分析汇总结果。',
      privacy_ok_button: '认可',
      privacy_cancel_button: '取消',
    },
    study: {
      why_do_you_think_that: '你为什么这么想？',
      scenario_how_likely_do_you_think: '根据你刚才读到的内容，你认为"%{contract}"的可能性有多大？',
      binary_how_likely_do_you_think: '考虑到这一点，你现在认为这有多大可能？',
      probability: '概率',
      scenario_with_a_probability_of: '"%{contract}" 的概率为 %{estimate}',
    }
  },
  'FR' : {
    ui: {
      please_stand_by: 'Veuillez vous tenir prêt',
    },
    chat: {
      bot_typing: 'Masspredict tape',
      estimate_slider: 'Je pense que c\'est probable à %{value}%.',
      privacy_header: 'Politique de confidentialité',
      please_read: 'Veuillez lire',
      privacy_first_line: 'Cette plateforme appartient à Masspredict, une société suédoise basée à Stockholm.',
      privacy_second_line: 'En utilisant notre plateforme, vous acceptez que nous stockions les réponses que vous donnez lors d\'études, comme celle à laquelle vous allez bientôt participer.',
      privacy_third_line: 'Toutes vos réponses seront totalement anonymes du début à la fin. Nous n\'avons aucun moyen de savoir qui vous êtes.',
      privacy_fourth_line: 'Si vous avez des questions ou des commentaires sur la plateforme, n\'hésitez pas à nous envoyer un courriel à l\'adresse suivante : info@masspredict.io.',
      privacy_collect_email: 'Nous vous demanderons votre adresse électronique à la fin du questionnaire afin de pouvoir vous contacter au sujet de toute récompense liée à l\'étude. Votre adresse électronique ne sera utilisée à aucune autre fin et ne sera pas associée à vos réponses.',
      privacy_collect_gener: 'Nous vous demanderons votre sexe au début de l\'étude. Ces informations ne sont utilisées que pour analyser les résultats dans leur ensemble.',
      privacy_ok_button: 'OK',
      privacy_cancel_button: 'Annuler',
    },
    study: {
      why_do_you_think_that: 'Pourquoi pensez-vous cela ?',
      scenario_how_likely_do_you_think: 'A quel point pensez-vous que "%{contract}" est probable à la lumière de ce que vous venez de lire ?',
      binary_how_likely_do_you_think: 'En tenant compte de cela, quelle est la probabilité que cela se produise ?',
      probability: 'probabilité',
      scenario_with_a_probability_of: '"%{contract}" avec une probabilité de %{estimate}',
    }
  },
  'DE' : {
    ui: {
      please_stand_by: 'Bitte bereithalten',
    },
    chat: {
      bot_typing: 'Masspredict tippt',
      estimate_slider: 'Ich denke, es ist zu %{value} wahrscheinlich.',
      privacy_header: 'Datenschutzbestimmungen',
      please_read: 'Bitte lesen',
      privacy_first_line: 'Diese Plattform gehört zu Masspredict, einem schwedischen Unternehmen mit Sitz in Stockholm.',
      privacy_second_line: 'Durch die Nutzung unserer Plattform erklären Sie sich damit einverstanden, dass wir die Antworten, die Sie im Rahmen von Studien, wie derjenigen, an der Sie in Kürze teilnehmen werden, geben, speichern.',
      privacy_third_line: 'Alle Ihre Antworten sind völlig anonym. Wir haben keine Möglichkeit zu erfahren, wer Sie sind.',
      privacy_fourth_line: 'Wenn Sie Fragen zur Plattform haben oder uns Feedback geben möchten, senden Sie uns eine E-Mail: info@masspredict.io.',
      privacy_collect_email: 'Nach Abschluss der Studie werden wir Sie um Ihre E-Mail-Adresse bitten, damit wir Sie über eventuelle Belohnungen für die Studie informieren können. Ihre E-Mail-Adresse wird für keinen anderen Zweck verwendet und nicht mit Ihren Antworten in Verbindung gebracht.',
      privacy_collect_gener: 'Wir werden Sie zu Beginn der Studie nach Ihrem Geschlecht fragen. Diese Informationen werden nur zur Analyse der Gesamtergebnisse verwendet.',
      privacy_ok_button: 'OK',
      privacy_cancel_button: 'Abbrechen',
    },
    study: {
      why_do_you_think_that: 'Wie kommst du darauf?',
      scenario_how_likely_do_you_think: 'How likely do you think "%{contract}" is in light of what you just read?',
      binary_how_likely_do_you_think: 'Für wie wahrscheinlich halten Sie dies nun unter Berücksichtigung dieser Tatsache?',
      probability: 'Wahrscheinlichkeit',
      scenario_with_a_probability_of: '"%{contract}" mit einer Wahrscheinlichkeit von %{estimate}',
    }
  },
  'ES' : {
    ui: {
      please_stand_by: 'Por favor',
    },
    chat: {
      bot_typing: 'Masspredict está escribiendo',
      estimate_slider: 'Creo que es %{value}% probable',
      privacy_header: 'Política de privacidad',
      please_read: 'Por favor, lea',
      privacy_first_line: 'Esta plataforma pertenece a Masspredict, una empresa sueca con sede en Estocolmo',
      privacy_second_line: 'Al utilizar nuestra plataforma, aceptas que almacenemos las respuestas que das durante los estudios, como en el que participarás próximamente',
      privacy_third_line: 'Todas sus respuestas son completamente anónimas. No tenemos forma de saber quién es usted',
      privacy_fourth_line: 'Si tienes alguna pregunta o comentario sobre la plataforma, no dudes en enviarnos un correo electrónico a: info@masspredict.io.',
      privacy_collect_email: 'Le pediremos su correo electrónico al finalizar para poder ponernos en contacto sobre cualquier recompensa del estudio. Su dirección de correo electrónico no se utilizará para ningún otro fin y no se asociará a sus respuestas',
      privacy_collect_gener: 'Le pediremos su sexo al inicio del estudio. Esta información sólo se utiliza para analizar los resultados en conjunto.',
      privacy_ok_button: 'OK',
      privacy_cancel_button: 'Cancelar',
    },
    study: {
      why_do_you_think_that: '¿Por qué piensas eso?',
      scenario_how_likely_do_you_think: '¿Qué probabilidad cree que tiene "%{contract}" a la luz de lo que acaba de leer?',
      binary_how_likely_do_you_think: 'Teniendo esto en cuenta, ¿qué probabilidades cree que tiene ahora?',
      probability: 'probabilidad',
      scenario_with_a_probability_of: '"%{contract}" con una probabilidad de %{estimate}',
    }
  },
};

export const available_languages = {
  'EN-GB': 'english',
  'SV': 'swedish',
  'ZH': 'chinese',
  'FR': 'french',
  'DE': 'german',
  'ES': 'spanish',
};

const i18n = new VueI18n({
  locale: 'EN-GB',
  messages,
});

export default i18n;
