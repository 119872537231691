import { IExternalParticipantsCount } from '@/interfaces';
import { IExternalParticipants } from './state';
import { IState } from '../state';
import { getStoreAccessors } from 'typesafe-vuex';

export const mutations = {
  setNumberOfParticipants(state: IExternalParticipants, numOfParticipants: number) {
    state.participants.male_participants = numOfParticipants;
  },
  setLucidFeasibility(state: IExternalParticipants, feasibility: number) {
    state.lucid_audience_estimate.feasibility = feasibility;
  },
  setLucidPrice(state: IExternalParticipants, price: number) {
    state.lucid_audience_estimate.price = price;
  },
  setLucidSampleProbability(state: IExternalParticipants, sample_probability: number) {
    state.lucid_audience_estimate.sample_probability = sample_probability;
  },
};

const { commit } = getStoreAccessors<IExternalParticipants, IState>('');
export const commitSetNumberOfParticipants = commit(mutations.setNumberOfParticipants);
export const commitSetLucidFeasibility = commit(mutations.setLucidFeasibility);
export const commitSetLucidPrice = commit(mutations.setLucidPrice);
export const commitSetLucidSampleProbability = commit(mutations.setLucidSampleProbability);
