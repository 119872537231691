import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IState } from '../state';
import { ISessionDataState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetSessionData,
  commitDeleteQuestion,
  commitUpdateQuestions,
  commitupdateLucidSurveyNumber,
  commitSetQuestionResponsesData,
  commitUpdateQuestion,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { IQuestionCreate, IQuestionData, IQuestionUpdate, IQuestionOrderMapping, IRecruitParams, IQuestionImageUpdate, IContract, IQuestionVideo } from '@/interfaces';

type MainContext = ActionContext<ISessionDataState, IState>;

export const actions = {
  async actionGetSessionData(context: MainContext, payload: { id: string }) {
    try {
      const response = await api.getSessionData(context.rootState.main.token, payload.id);
      if (response) {
        commitSetSessionData(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPublicSessionData(context: MainContext, payload: { id: string }) {
    try {
      const response = await api.getPublicStudyData(payload.id);
      if (response) {
        commitSetSessionData(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteQuestion(context: MainContext, payload: { sessionId: string; questionId: string }) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.deleteQuestion(context.rootState.main.token, payload.questionId),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]))[0];
      commitDeleteQuestion(context, payload.questionId);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Question successfully deleted', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },

  async actionUpdateContract(context: MainContext, payload: IContract[]) {
    try {
      const loadingNotification = { content: 'updating', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateContract(context.rootState.main.token, payload),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Contracts successfully updated', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateQuestion(context: MainContext, payload: { sessionId: string; questionId: string; question: IQuestionUpdate }) {
    try {
      const loadingNotification = { content: 'updating', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateQuestion(context.rootState.main.token, payload.questionId, payload.question),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]))[0];
      commitUpdateQuestion(context, {question_id: payload.questionId, data: payload.question});
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Question successfully updated', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateQuestions(context: MainContext, payload: { sessionId: string; questions: IQuestionCreate[] }) {
    try {
      const loadingNotification = { content: 'creating', showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        await api.createQuestions(context.rootState.main.token, payload.sessionId, payload.questions),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]).then(async () => {
        const response = await api.getSessionData(context.rootState.main.token, payload.sessionId);
        if (response) {
          commitSetSessionData(context, response.data);
        }
      });
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Question successfully created', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionOrderQuestions(context: MainContext, payload: { sessionId: string; questions: IQuestionData[] }) {
    try {
      const orderMapping = [] as IQuestionOrderMapping[];
      payload.questions.forEach((value, index) => {
        const data = {} as IQuestionOrderMapping;
        data.id = value.id;
        data.order_idx = index;
        orderMapping.push(data);
      });
      const loadingNotification = { content: 'updating', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await (Promise.all([
        await api.updateQuestionsOrder(context.rootState.main.token, payload.sessionId, orderMapping),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]));
      if (response[0].status === 200) {
        commitUpdateQuestions(context, payload.questions);
      }
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Session successfully updated', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionRecruitExternal(context: MainContext, payload: { sessionId: string; params: IRecruitParams }) {
    try {
      const loadingNotification = { content: 'Recruiting...', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await Promise.all([
        await api.recruitParticipants(context.rootState.main.token, payload.sessionId, payload.params),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]);
      if (response[0].status === 200) {
        commitupdateLucidSurveyNumber(context, response[0].data.lucid_survey_nr);
      }
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Success!', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateQuestionImage(context: MainContext, payload: { sessionId: string; questionImageId: string; questionImage: IQuestionImageUpdate }) {
    try {
      const loadingNotification = { content: 'updating', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateQuestionImageCaption(context.rootState.main.token, payload.questionImageId, payload.questionImage),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Caption successfully updated', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteQuestionImage(context: MainContext, payload: { sessionId: string; questionImageId: string }) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.deleteQuestionImage(context.rootState.main.token, payload.questionImageId),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]))[0];
      commitDeleteQuestion(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Image successfully deleted', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetQuestionResponses(context: MainContext, payload: { questionId: string; pageLength: number; pageNumber: number; search: string; selectedScores?: number[]}) {
    try {
      const response = await api.getQuestionResponses(context.rootState.main.token, payload.questionId, payload.pageLength, payload.pageNumber, payload.search, payload.selectedScores);
      if (response) {
        commitSetQuestionResponsesData(context, { responses: response.data, questionId: payload.questionId });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteQuestionVideo(context: MainContext, payload: { sessionId: string; questionVideoId: string }) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.deleteQuestionVideo(context.rootState.main.token, payload.questionVideoId),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]))[0];
      commitDeleteQuestion(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Video successfully deleted', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateQuestionVideo(context: MainContext, payload: { sessionId: string; questionId: string; questionVideos: IQuestionVideo[] }) {
    try {
      const loadingNotification = { content: 'creating', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.createQuestionVideo(context.rootState.main.token, payload.questionId, payload.questionVideos),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500))
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Video successfully created', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<ISessionDataState, IState>('');
export const dispatchUpdateContract = dispatch(actions.actionUpdateContract);
export const dispatchGetSessionData = dispatch(actions.actionGetSessionData);
export const dispatchGetPublicSessionData = dispatch(actions.actionGetPublicSessionData);
export const dispatchDeleteQuestion = dispatch(actions.actionDeleteQuestion);
export const dispatchUpdateQuestion = dispatch(actions.actionUpdateQuestion);
export const dispatchCreateQuestions = dispatch(actions.actionCreateQuestions);
export const dispatchOrderQuestions = dispatch(actions.actionOrderQuestions);
export const dispatchRecruitExternal = dispatch(actions.actionRecruitExternal);
export const dispatchUpdateQuestionImage = dispatch(actions.actionUpdateQuestionImage);
export const dispatchDeleteQuestionImage = dispatch(actions.actionDeleteQuestionImage);
export const dispatchGetQuestionResponses = dispatch(actions.actionGetQuestionResponses);
export const dispatchCreateQuestionVideo = dispatch(actions.actionCreateQuestionVideo);
export const dispatchDeleteQuestionVideo = dispatch(actions.actionDeleteQuestionVideo);
