






















import { Component, Vue } from 'vue-property-decorator';
import NotificationsManager from '@/components/NotificationsManager.vue';
// import { sentryDsn, environment } from '@/env';
import FeedbackButton from '@/components/FeedbackButton.vue';
import { readIsLoggedIn } from '@/store/main/getters';
import { dispatchCheckLoggedIn } from '@/store/main/actions';

// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';

// Sentry.init({
//   Vue,
//   dsn: sentryDsn,
//   beforeSend(event, hint) {
//     if (event.exception) {
//       const feedback = event.exception.values?.[0].value === 'feedback';
//       if (feedback) {
//         Sentry.showReportDialog({
//           eventId: event.event_id,
//           title: 'Feedback Submission',
//           subtitle: `Thank you for taking the time to help us improve the platform,
//           if you want to be anonymous simply leave the name and email fields as they are.`,
//           subtitle2: '',
//           labelSubmit: 'Submit Feedback',
//           labelComments: 'Feedback',
//           user: {
//             name: 'anonymous',
//             email: 'anonymous@anonymous.com',
//           }
//         });
//       }
//       // else {
//       //   Sentry.showReportDialog({
//       //     eventId: event.event_id,
//       //     subtitle2: 'If you want to be anonymous simply leave the name and email fields as they are.',
//       //     user: {
//       //       name: 'anonymous',
//       //       email: 'anonymous@anonymous.com',
//       //     }
//       //   });
//       // }
//     }
//     return event;
//   },
//   environment: environment,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 0.2,
// });

@Component({
  components: {
    NotificationsManager,
    FeedbackButton,
  },
})
export default class App extends Vue {

  get loggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public async created() {
    await dispatchCheckLoggedIn(this.$store);
  }

}
