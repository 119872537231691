import { IQuestionData, IQuestion, IStudyData, IParticipantWithResponse, IQuestionWithParticipantCount, IQuestionTopicsData, IQuestionWithTopics, IQuestionUpdate } from '@/interfaces';
import { ISessionDataState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { IState } from '../state';

export const mutations = {
  setSessionData(state: ISessionDataState, payload: IStudyData) {
    state.data = payload.data;
    state.responses = payload.responses;
    state.topics = payload.topics;
    state.ai_recruitment = payload.ai_recruitment;
    state.open_ended_question_topics = payload.open_ended_question_topics;
  },
  deleteQuestion(state: ISessionDataState, question_id: string) {
    const questionsWithResponses = state.responses.questions.filter(
      (question: IQuestionWithParticipantCount) => question.id !== question_id
    );
    const questionsWithData = state.data.questions_with_data.filter(
      (question: IQuestionData) => question.id !== question_id
    );
    state.responses.questions = questionsWithResponses;
    state.data.questions_with_data = questionsWithData;
  },
  updateQuestion(state: ISessionDataState, payload: {question_id: string, data: IQuestionUpdate}) {
    state.data.questions_with_data.map((question) => {
      if (question.id === payload.question_id) {
        for (const key in payload.data) {
          if (payload.data[key] !== undefined) {
            question[key] = payload.data[key];
          }
        }
      }
    });
  },
  updateQuestionsOrder(state: ISessionDataState, payload: IQuestionData[]) {
    state.data.questions_with_data = payload;
  },
  updateLucidSurveyNumber(state: ISessionDataState, payload: string) {
    state.data.lucid_survey_nr = payload;
  },
  setQuestionResponsesData(state: ISessionDataState, payload: { questionId: string; responses: IParticipantWithResponse[] }) {
    const question_resp = state.response_data.questions.find(q => q.id == payload.questionId);
    if (question_resp) {
      question_resp.participants = payload.responses;
    } else {
      state.response_data.questions.push({ id: payload.questionId, participants: payload.responses });
    }
  },
  setQuestionTopicsDrivers(state: ISessionDataState, payload: IQuestionWithTopics) {
    const topics_data = state.topics;
    for (let i = 0; i < topics_data.questions.length; i++) {
      if (topics_data.questions[i].id == payload.id) {
        topics_data.questions[i] = payload;
      }
    }
    state.topics = topics_data;
  },
};

const { commit } = getStoreAccessors<ISessionDataState, IState>('');

export const commitSetSessionData = commit(mutations.setSessionData);
export const commitDeleteQuestion = commit(mutations.deleteQuestion);
export const commitUpdateQuestion = commit(mutations.updateQuestion);
export const commitUpdateQuestions = commit(mutations.updateQuestionsOrder);
export const commitupdateLucidSurveyNumber = commit(mutations.updateLucidSurveyNumber);
export const commitSetQuestionResponsesData = commit(mutations.setQuestionResponsesData);
export const commitSetQuestionTopicsDrivers = commit(mutations.setQuestionTopicsDrivers);
