import { api } from '@/api';
import { ActionContext } from 'vuex';
import { 
  ILucidSettings,
  IProlificSettings,
  IUserProfileCreate,
  IUserProfileUpdate
} from '@/interfaces';
import { IState } from '../state';
import { IAdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetUsers,
  commitSetUser,
  commitSetAdminSession,
  commitSetAdminSessions,
  commitSetBetaSignees,
  commitSetBetaSignee,
  commitDeleteUser,
  commitDeleteBetaSignee,
  commitSetLucidSettings,
  commitSetProlificSettings,
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<IAdminState, IState>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUser(context: MainContext, payload: { id: string; user: IUserProfileUpdate }) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateUser(context.rootState.main.token, payload.id, payload.user),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'User successfully updated', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.createUser(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'User successfully created', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteUser(context: MainContext, userId: string) {
    try {
      const loadingNotification = { content: 'Deleting...', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.deleteUser(context.rootState.main.token, userId),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitDeleteUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'User successfully deleted', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionActivateUser(context: MainContext, payload: { confirmation_code: string; user: IUserProfileUpdate }) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.activateUser(context.rootState.main.token, payload.confirmation_code, payload.user),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'User account successfully activated. Please login.', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteBetaSignee(context: MainContext, id: string) {
    try {
      const loadingNotification = { content: 'Deleting...', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.deleteBetaSignee(context.rootState.main.token, id),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitDeleteBetaSignee(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Beta signee successfully deleted', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAddUserCredits(context: MainContext, payload: { id: string; credits: number; note?: string }) {
    try {
      const loadingNotification = { content: 'Saving..', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.addUserCredits(context.rootState.main.token, payload.id, payload.credits, payload.note),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Credits successfully added', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetAdminSessions(context: MainContext) {
    try {
      const response = await api.getAllSessions(context.rootState.main.token);
      if (response) {
        commitSetAdminSessions(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetLucidSettings(context: MainContext) {
    try {
      const response = await api.lucidSettings(context.rootState.main.token);
      if (response) {
        commitSetLucidSettings(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetProlificSettings(context: MainContext) {
    try {
      const response = await api.prolificSettings(context.rootState.main.token);
      if (response) {
        commitSetProlificSettings(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSetSessionPublic(context: MainContext, payload: { id: string }) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.setSessionPublic(context.rootState.main.token, payload.id),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetAdminSession(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Session successfully updated', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetBetaSignees(context: MainContext) {
    try {
      const response = await api.getBetaSignees(context.rootState.main.token);
      if (response) {
        commitSetBetaSignees(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateLucidSettings(context: MainContext, payload: ILucidSettings) {
    try {
      const loadingNotification = { content: 'Saving...', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateLucidSettings(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Lucid settings successfully updated.', color: 'success' });
      commitSetLucidSettings(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateProlificSettings(context: MainContext, payload: IProlificSettings) {
    try {
      const loadingNotification = { content: 'Saving...', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateProlificSettings(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Prolific settings successfully updated.', color: 'success' });
      commitSetProlificSettings(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionApproveBetaSignee(context: MainContext, payload: { id: string }) {
    try {
      const loadingNotification = { content: 'Approving...', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.approveBetaSignee(context.rootState.main.token, payload.id),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetBetaSignee(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Beta user successfully created.', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<IAdminState, IState>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchDeleteUser = dispatch(actions.actionDeleteUser);
export const dispatchAddUserCredits = dispatch(actions.actionAddUserCredits);
export const dispatchGetAdminSessions = dispatch(actions.actionGetAdminSessions);
export const dispatchSetSessionPublic = dispatch(actions.actionSetSessionPublic);
export const dispatchGetBetaSignees = dispatch(actions.actionGetBetaSignees);
export const dispatchDeleteBetaSignee = dispatch(actions.actionDeleteBetaSignee);
export const dispatchApproveBetaSignee = dispatch(actions.actionApproveBetaSignee);
export const dispatchGetLucidSettings = dispatch(actions.actionGetLucidSettings);
export const dispatchUpdateLucidSettings = dispatch(actions.actionUpdateLucidSettings);
export const dispatchGetProlificSettings = dispatch(actions.actionGetProlificSettings);
export const dispatchUpdateProlificSettings = dispatch(actions.actionUpdateProlificSettings);
export const dispatchActivateUser = dispatch(actions.actionActivateUser);