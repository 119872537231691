import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'signup',
          component: () => import(/* webpackChunkName: "signup" */ './views/Signup.vue'),
        },
        {
          path: 'slack-bind/:nonce',
          component: () => import(/* webpackChunkName: "slack-bind" */ './views/SlackBind.vue'),
        },
        {
          path: 'social-login',
          component: () => import(/* webpackChunkName: "social-login" */ './views/SocialLogin.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'chat/:id',
          component: () => import(/* webpackChunkName: "session-chat" */ './views/study/views/Chat.vue'),
          props: (route) => ({ rid: route.query.rid }),
        },
        {
          path: 'activate-account/:confirmation_code',
          component: () => import(/* webpackChunkName: "activate-account" */ './views/ActivateAccount.vue'),
        },
        {
          path: 'public/study/:id',
          component: () => import(/* webpackChunkName: "public-study" */ './views/PublicStudy.vue'),
        },
        {
          path: 'results/:id',
          component: () => import(/* webpackChunkName: "public-study" */ './views/main/session/PublicResults.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'faq',
              component: () => import(/* webpackChunkName: "faq" */ './views/main/FAQ.vue'),
            },
            {
              path: 'user',
              component: RouterComponent,
              redirect: 'user/session/all',
              children: [
                {
                  path: 'session/all',
                  name: 'main-session-sessions',
                  component: () => import(
                    /* webpackChunkName: "main-session-sessions" */ './views/main/session/Studies.vue'),
                },
                {
                  path: 'view/:id',
                  name: 'main-user-session-view',
                  component: () => import(
                    /* webpackChunkName: "main-user-session-view" */ './views/main/session/StudyNew.vue'),
                },
                {
                  path: 'create',
                  name: 'main-session-create',
                  component: () => import(
                    /* webpackChunkName: "main-user-session-create" */ './views/main/session/CreateStudy.vue'),
                },
                {
                  path: 'credits',
                  name: 'main-credits-products',
                  component: () => import(
                    /* webpackChunkName: "main-credits-products" */ './views/main/Credits/Credits.vue'),
                  props: (route) => ({ statusIn: route.query.statusIn }),
                },
                {
                  path: 'plans',
                  name: 'main-plans',
                  component: () => import(
                    /* webpackChunkName: "main-credits-products" */ './views/main/Credits/Plans.vue'),
                  props: (route) => ({ statusIn: route.query.statusIn }),
                },
                {
                  path: 'pairwise/all',
                  name: 'main-paiwise-all',
                  component: () => import(
                    /* webpackChunkName: "main-pairwise-all" */ './views/main/pairwise/PairwiseList.vue'),
                },
                {
                  path: 'pairwise/new',
                  name: 'main-paiwise-new',
                  component: () => import(
                    /* webpackChunkName: "main-pairwise-all" */ './views/main/pairwise/Pairwise.vue'),
                },
                {
                  path: 'pairwise/:id',
                  name: 'main-paiwise-edit',
                  component: () => import(
                    /* webpackChunkName: "main-pairwise-all" */ './views/main/pairwise/Pairwise.vue'),
                },
              ],
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
                {
                  path: 'studies',
                  name: 'main-admin-studies',
                  component: () => import(
                    /* webpackChunkName: "main-admin-studies" */ './views/main/admin/AdminStudies.vue'),
                },
                {
                  path: 'beta-signees',
                  redirect: 'beta-signees/all',
                },
                {
                  path: 'beta-signees/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-beta-signees" */ './views/main/admin/AdminBetaSignees.vue'),
                },
                {
                  path: 'lucid',
                  name: 'main-admin-lucid',
                  component: () => import(
                    /* webpackChunkName: "main-admin-lucid" */ './views/main/admin/AdminLucid.vue'),
                },
                {
                  path: 'ai-settings',
                  name: 'main-admin-ai-settings',
                  component: () => import(
                    /* webpackChunkName: "main-admin-lucid" */ './views/main/admin/AdminAISettings.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
