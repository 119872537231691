import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { IExternalParticipants } from './state';

const defaultState: IExternalParticipants = {
  participants: {
    male_participants: 0,
    female_participants: 0
  },
  lucid_audience_estimate: {
    feasibility: 0.0,
    price: 0.0,
    sample_probability: 0.0,
  }
};

export const RecruitDataModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
