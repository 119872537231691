import { api } from '@/api';
import { ActionContext } from 'vuex';
import { ISessionCreate, ISessionUpdate } from '@/interfaces';
import { IState } from '../state';
import { ISessionsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetSession, commitSetSessions, commitDeleteSessions } from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<ISessionsState, IState>;

export const actions = {
  async actionGetSessions(context: MainContext) {
    try {
      const response = await api.getSessions(context.rootState.main.token);
      if (response) {
        commitSetSessions(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateSession(context: MainContext, payload: { id: string; session: ISessionUpdate }) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.updateSession(context.rootState.main.token, payload.id, payload.session),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetSession(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Session successfully updated', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateSession(context: MainContext, payload: ISessionCreate) {
    try {
      const loadingNotification = { content: 'saving', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.createSession(context.rootState.main.token, payload),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitSetSession(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Session successfully created', color: 'success' });
    } catch (error) {
      commitAddNotification(context, { content: 'Cannot create session', color: 'error' });
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteSession(context: MainContext, sessionId: string) {
    try {
      const loadingNotification = { content: 'deleting', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.deleteSession(context.rootState.main.token, sessionId),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitDeleteSessions(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Session successfully deleted', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCopySession(context: MainContext, sessionId: string) {
    try {
      const loadingNotification = { content: 'copying', showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (await Promise.all([
        api.copySession(context.rootState.main.token, sessionId),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]))[0];
      commitDeleteSessions(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: 'Session successfully copied', color: 'success' });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};


const { dispatch } = getStoreAccessors<ISessionsState, IState>('');

export const dispatchCreateSession = dispatch(actions.actionCreateSession);
export const dispatchGetSessions = dispatch(actions.actionGetSessions);
export const dispatchUpdateSession = dispatch(actions.actionUpdateSession);
export const dispatchDeleteSession = dispatch(actions.actionDeleteSession);
export const dispatchCopySession = dispatch(actions.actionCopySession);
