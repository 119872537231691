import { api } from '@/api';
import { ActionContext } from 'vuex';
import { IState } from '../state';
import { IExternalParticipants } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import {
  commitSetNumberOfParticipants,
  commitSetLucidFeasibility,
  commitSetLucidPrice,
  commitSetLucidSampleProbability
} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { IRecruitParams } from '@/interfaces';

type MainContext = ActionContext<IExternalParticipants, IState>;
export const actions = {
  async actionCountParticipants(context: MainContext, payload: { id: string; params: IRecruitParams }) {
    try {
      const response = await api.countParticipants(context.rootState.main.token, payload.id, payload.params);
      if (response) {
        commitSetNumberOfParticipants(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetLucidAudienceEstimate(context: MainContext, payload: { id: string; params: IRecruitParams }) {
    try {
      const response = await api.getLucidAudienceEstimate(context.rootState.main.token, payload.id, payload.params);
      if (response) {
        commitSetLucidFeasibility(context, response.data.feasibility);
        commitSetLucidPrice(context, response.data.price);
        commitSetLucidSampleProbability(context, response.data.sample_probability);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<IExternalParticipants, IState>('');
export const dispatchGetNumberOfParticipants = dispatch(actions.actionCountParticipants);
export const dispatchActionGetLucidAudienceEstimate = dispatch(actions.actionGetLucidAudienceEstimate);