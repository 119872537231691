import { IAdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { IState } from '../state';

export const getters = {
  adminUsers: (state: IAdminState) => state.users,
  adminOneUser: (state: IAdminState) => (userId: string) => {
    const filteredUsers = state.users.filter((user) => user.id === userId);
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  adminSessions: (state: IAdminState) => state.sessions,
  adminOneSession: (state: IAdminState) => (sessionId: string) => {
    const filteredSessions = state.sessions.filter((session) => session.id === sessionId);
    if (filteredSessions.length > 0) {
      return { ...filteredSessions[0] };
    }
  },
  adminSessionsByState: (state: IAdminState) => (state_values: object) => {
    const filteredSessions = state.sessions.filter((session) => {
      return state_values[session.state]
    });
    if (filteredSessions.length > 0) {
      return filteredSessions;
    }
  },
  adminApprovedBetaSignees: (state: IAdminState) => {
    const filteredbetaSignees = state.betaSignees.filter((betaSignee) => betaSignee.approved);
    if (filteredbetaSignees.length > 0) {
      return filteredbetaSignees;
    }
  },
  adminWaitingBetaSignees: (state: IAdminState) => {
    const filteredbetaSignees = state.betaSignees.filter((betaSignee) => !betaSignee.approved);
    if (filteredbetaSignees.length > 0) {
      return filteredbetaSignees;
    }
  },
  lucidSettings: (state: IAdminState) => state.lucidSettings,
  prolificSettings: (state: IAdminState) => state.prolificSettings,
};


const { read } = getStoreAccessors<IAdminState, IState>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminOneSession = read(getters.adminOneSession);
export const readAdminSessions = read(getters.adminSessions);
export const readAdminSessionsByState = read(getters.adminSessionsByState);
export const readAdminApprovedBetaSignees = read(getters.adminApprovedBetaSignees);
export const readAdminWaitingBetaSignees = read(getters.adminWaitingBetaSignees);
export const readLucidSettings = read(getters.lucidSettings);
export const readProlificSettings = read(getters.prolificSettings);
