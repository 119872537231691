import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { IAdminState } from './state';

const defaultState: IAdminState = {
  users: [],
  sessions: [],
  betaSignees: [],
  lucidSettings: {
    quotaCPI_FR_1Q: 0.01,
    quotaCPI_FR_2Q: 0.01,
    quotaCPI_FR_3Q: 0.01,
    quotaCPI_SE_1Q: 0.01,
    quotaCPI_SE_2Q: 0.01,
    quotaCPI_SE_3Q: 0.01,
    quotaCPI_CN_1Q: 0.01,
    quotaCPI_CN_2Q: 0.01,
    quotaCPI_CN_3Q: 0.01,
  },
  prolificSettings: {
    quotaCPI_UK_1M: 0.15,
    quotaCPI_US_1M: 0.15,
  },


};

export const adminModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
