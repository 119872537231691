import { IUserProfile } from '@/interfaces';
import { IMainState, IAppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { IState } from '../state';


export const mutations = {
  setToken(state: IMainState, payload: string) {
    state.token = payload;
  },
  setLoggedIn(state: IMainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: IMainState, payload: boolean) {
    state.logInError = payload;
  },
  setUserProfile(state: IMainState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  setDashboardMiniDrawer(state: IMainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: IMainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: IMainState, payload: IAppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: IMainState, payload: IAppNotification) {
    state.notifications = state.notifications.filter((notification) => notification !== payload);
  },
};

const {commit} = getStoreAccessors<IMainState | any, IState>('');

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
